<template>
  <section class="banner-area">
    <h1 class="banner_heading" v-if="type === 'flight-only'">BLUE BIRD AIR</h1>
    <b-carousel
      v-model="slide"
      :interval="20000"
      indicators
      ref="myCarousel"
      class="banner-carousel"
      style="text-shadow: 1px 1px 2px #333"
    >
      <b-carousel-slide
        v-for="(item, index) in imageList"
        :img-src="typeof(item) === 'string' ? item : item.image"
        :key="index"
      ></b-carousel-slide>
        <!-- text-tag="div"
        :caption-html="item.caption || ''"
        :text-html="getSubImageTags(item) || ''" -->
    </b-carousel>
    <SearchTabs v-if="type === 'search-result' || type === 'home'" />
  </section>
</template>

<script>
import { BCarousel, BCarouselSlide } from 'bootstrap-vue';

export default {
  name: 'Bnner',
  components: {
    BCarousel,
    BCarouselSlide,
    SearchTabs: () => import('@/components/search/SearchTabs'),
  },
  props: {
    imageList: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      defailt: '',
    },
  },
  data() {
    return {
      slide: 0,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
        1000: {
          items: 1,
        },
      },
    };
  },
  mounted() {
    // console.log(this.imageList);
  },
  methods: {
    getSubImageTags(oneItem) {
      const imageList = oneItem?.cmgoExt?.subImages;
      const description = this.getSubDescription(oneItem);
      if (!imageList) return description;
      const styleWidth = imageList.length > 4 ? '25' : (100 / imageList.length).toFixed(0);
      const listTags = imageList.map((img) => (`<div class="column" style="width: ${styleWidth}%;">
          <img class="img" src="${img}">
        </div>`)).join('');
      return `
        <p class="sub-image-desc" style="color: ${oneItem.textColor}">${description}</p>
        <hr style="margin: 0;"/>
        <div class="row">
          ${listTags}
        </div>
      `;
    },
    getSubDescription(oneItem) {
      return oneItem?.cmgoExt?.description || '';
    },
  },
};
</script>

<style>
.carousel.banner-carousel.slide .carousel-inner .carousel-item,
.carousel.banner-carousel.slide .carousel-inner .carousel-item .img-fluid {
  height: inherit;
}
.carousel-item, .carousel-item > img{
  height: 100%;
}
/* .banner-carousel .carousel-indicators li {
  display: inline-block;
  zoom: 1;
  background: 0 0;
  color: inherit;
  padding: 0 !important;
  font: inherit;

  height: 1em;
  width: 1em;
  transition: all 0.5s ease-in-out;
  margin: 5px 7px;
  border: solid 1px #171717;
}
.banner-carousel .carousel-indicators .active {
  background-color: #171717;
}
.banner-carousel .carousel-caption,
.banner-carousel .carousel-indicators {
  bottom: 200px;
  width: 30%;
  margin-bottom: 0;
  left: auto;
}
.banner-carousel .carousel-caption {
  background: rgba(0, 160, 253, 0.5);
  padding-bottom: 2em;
  border-top: solid 4px #0061ab;
}
.banner-carousel .carousel-indicators {
  padding: 0.5em;
  background: rgb(255 255 255 / 50%);
} */
  .banner-carousel .carousel-indicators li {
    display: inline-block;
    zoom: 1;
    background: 0 0;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;

    height: 1em;
    width: 1em;
    background-color: #ffffffa8;
    transition: all 0.5s ease-in-out;
    margin: 5px 7px;
    border-radius: 30px;
  }
  .banner-carousel .carousel-indicators .active {
    background-color: #00ffff;
  }
  .banner-carousel .carousel-indicators {
    transform: rotate(-90deg) translateY(-50%) scale(1, -1);
    right: 0;
    position: absolute;
    top: 50%;
    bottom: auto;
    left: auto;
    display: block;
    justify-content: unset;
    padding: inherit;
    margin: auto;
  }
.banner-carousel .carousel-caption div.row{
  overflow-wrap: break-word;
  padding: 1em;
}
@media (max-width: 479px) {
  .banner-carousel {
    display: none;
  }
}
@media (min-width: 1200px) {
  .carousel-inner {
    height: calc(100vh - 56px);
  }
}
@media only screen and (max-width: 1199px) {
  .banner-carousel .carousel-caption,
  .banner-carousel .carousel-indicators {
    bottom: 0;
  }
}
.banner-area .slide {
  height: auto;
}

.banner-area .row > .column {
  padding: 8px;
}

.banner-area .row:after {
  content: "";
  display: table;
  clear: both;
}

/* Create four equal columns that floats next to eachother */
/* .banner-area .column {
  float: left;
}
.banner-area .column .img{
  width: 100%;
  height: 100%;
}
.banner-area .sub-image-desc{
  font-size: 1.3rem;
} */

</style>
